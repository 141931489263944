/* TODO split into smaller files */
.ps-form-card, .ps-form-version-card, .ps-form-data-card {
	filter: brightness(1);
	border-radius:0.5em;
	box-shadow: 0em 0.1em 0.6em 0em;
	padding:1em;
	margin:1em;
	text-align: center;
	background-image: linear-gradient(0deg, rgba(0,0,0,0.2), rgba(255,255,255,0.2));
	.ps-btn-primary, .ps-btn-danger, .ps-btn-success, .ps-btn-warning {
		text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
		background-image: linear-gradient(135deg, rgba(255,255,255,0.5), transparent);
		box-shadow: inset 0px -2px 1px 0px rgba(0,0,0,0.5), 0px 4px 4px -2px rgba(0,0,0,0.5);
		margin: 0.2em;
		color: white
	}
	.ps-btn-primary:hover, .ps-btn-danger:hover, .ps-btn-success:hover, .ps-btn-warning:hover {
		filter: brightness(1.1);
	}
	h3 {
		border-bottom: 0.1em solid;
		text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
	}
	.ps-slider-off-warning{
		background-color: var(--fg-warning);
	}
	input:checked + .ps-slider-on-danger{
		background-color: var(--fg-danger);
	}
	.ps-slider:hover {
		filter: brightness(1.1);
	}
	.ps-slider{
		box-shadow: inset 0px 2px 1px 0px rgba(0,0,0,0.5);
		overflow: hidden;
	}
	.ps-slider:before{
		box-shadow: inset 0px -2px 1px 0px rgba(0,0,0,0.5), 0px 4px 4px -2px rgba(0,0,0,0.5);
	}
}

.cm-highlightTab {
	opacity: 0.2;
}

.big-centered-icon {
	font-size: 12em;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: black;
	z-index: -1;
	opacity: 0.2;
	filter: blur(2px);
	overflow: hidden;
}
